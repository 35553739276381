// import Vue from 'vue'
// const that = Vue.prototype

export default [
    {
        title: 'داشبورد',
        route: 'Dashboard',
        icon: 'HomeIcon',
        role: ['all']
    },
    {
        title: 'کاربران سایت',
        route: 'Accounts',
        icon: 'UsersIcon',
        role: ['LIST_USERS']
    },
    {
        title: 'مدیریت سامانه',
        icon: 'MonitorIcon',
        role: ['LIST_ADMINS', 'LIST_ROLES'],
        children: [
            {
                title: 'مدیران',
                route: 'admin-accounts',
                role: ['LIST_ADMINS']
            },
            {
                title: 'نقش ها و دسترسی ها',
                route: 'admin-roles',
                role: ['LIST_ROLES']
            },
            {
                title: 'افزودن نقش',
                route: 'add-role',
                role: ['CREATE_ROLES']
            },
            {
                title: 'مشاهده نقش',
                route: 'show-role',
                role: ['UPDATE_ROLES'],
                disabled: true
            },
        ],
    },
    {
        title: 'مدیریت دلار',
        route: 'dollar',
        icon: 'DollarSignIcon',
        role: ['LIST_DOLLARS'],
    },
    {
        title: 'مدیریت رمز ارز ها',
        tagVariant: 'light-info',
        icon: 'CpuIcon',
        role: ['UPDATE_MARKET','UPDATE_EXCHANGE'],
        children: [
            {
                title: 'ازرهای پلتفرم معاملاتی',
                route: 'cryptocurrency',
                role: ['UPDATE_MARKET'],
            },
            {
                title: 'ارزهای صرافی',
                route: 'exchange-cryptocurrency',
                role: ['UPDATE_MARKET'],
            },
        ]
    },
    {
        title: 'سطوح کاربری',
        route: 'account-levels',
        tagVariant: 'light-info',
        // tag: 'new',
        icon: 'BarChartIcon',
        role: ['UPDATE_ACCOUNT_LEVEL'],
    },
    {
        title: 'مدیریت مالی',
        icon: 'DollarSignIcon',
        role: ['LIST_HISTORIES', 'LIST_WITHDRAW', 'LIST_WALLET', 'LIST_DOLLARS'],
        children: [
            {
                title: 'مدیریت کیف پول ها',
                role: ['LIST_WALLET'],
                children: [
                    {
                        title: 'کیف پول پلتفرم معاملاتی',
                        route: 'wallets',
                        role: ['LIST_WALLET'],
                    },
                    {
                        title: 'کیف پول صرافی',
                        route: 'exchange-wallets',
                        role: ['LIST_WALLET'],
                    },
                ]
            },
            {
                title: 'کارت های هدیه',
                route: 'gift-cards',
                role: ['LIST_CARDS'],
            },
            {
                title: 'کارمزد های کسب شده',
                route: 'fees',
                role: ['LIST_WALLET'],
                children: [
                    {
                        title: 'پلتفرم معاملاتی',
                        route: 'fees',
                        role: ['LIST_WALLET'],
                    },
                    {
                        title: 'صرافی',
                        route: 'exchange-fees',
                        role: ['LIST_WALLET'],
                    },
                ]
            },
            {
                title: 'آدرس ها',
                route: 'addresses',
                role: ['DETAILS_WALLET'],
                children: [
                    /*{
                        title: 'کیف پول های تومانی',
                        route: 'toman-addresses',
                        role: ['DETAILS_WALLET']
                    },*/
                    {
                        title: 'آدرس های XLM/XRP',
                        route: 'x-addresses',
                        role: ['DETAILS_WALLET']
                    },
                    {
                        title: 'آدرس های ارز های دیگر',
                        route: 'hd-addresses',
                        role: ['DETAILS_WALLET']
                    },
                ]
            },
            {
                title: 'درخواست های برداشت',
                tagVariant: 'light-warning',
                tag: 'requestCount',
                route: 'withdraw-orders',
                role: ['LIST_WITHDRAW']
            },
            {
                title: 'درخواست واریز صرافی',
                tagVariant: 'light-warning',
                tag: 'depRequestCount',
                route: 'deposit-orders',
                role: ['LIST_WITHDRAW']
            },
            {
                title: 'درخواست های تجمیع',
                route: 'gather-requests',
                role: ['LIST_WITHDRAW']
            },

            {
                title: 'تاریخچه',
                route: 'history',
                role: ['LIST_WITHDRAW', 'LIST_TRANSACTIONS'],
                children: [
                    {
                        title: 'درخواست های برداشت',
                        route: 'withdraw-history',
                        role: ['LIST_WITHDRAW']
                    },
                    {
                        title: 'تراکنش های تومان',
                        route: 'toman-transactions',
                        role: ['LIST_TRANSACTIONS']
                    },
                    {
                        title: 'تراکنش های اتریومی',
                        route: 'ethereum-transactions',
                        role: ['LIST_TRANSACTIONS'],
                    },
                    {
                        title: 'تراکنش های XRP/XLM',
                        route: 'x-transactions',
                        role: ['LIST_TRANSACTIONS'],
                    },
                    {
                        title: 'تراکنش های ترون',
                        route: 'tron-transactions',
                        role: ['LIST_TRANSACTIONS'],
                    },
                    {
                        title: 'تراکنش های HD',
                        route: 'wallet-transactions',
                        role: ['LIST_TRANSACTIONS'],
                    },
                    {
                        title: 'سایر تراکنش ها',
                        route: 'other-transactions',
                        role: ['LIST_TRANSACTIONS'],
                    },
                ],
            },
        ],
    },
    {
        title: 'بازار ها',
        route: 'Markets',
        icon: 'TrendingUpIcon',
        role: ['LIST_MARKET']
    },
    {
        title: 'معاملات',
        route: 'Deals',
        icon: 'RepeatIcon',
        role: ['LIST_TRADES']

    },
    {
        title: 'معاملات otc',
        route: 'otc-trades',
        icon: 'ActivityIcon',
        role: ['LIST_TRADES']
    },
    {
        title: 'سفارشات',
        route: 'Orders',
        icon: 'ShoppingCartIcon',
        role: ['LIST_ORDERS']
    },
    {
        title: 'سفارشات ناقص صرافی',
        tagVariant: 'light-warning',
        tag: 'orderCount',
        route: 'manual-orders',
        icon: 'CreditCardIcon',
        role: ['LIST_WITHDRAW']
    },
    {
        title: 'پیام ها',
        route: 'Messages',
        icon: 'MessageSquareIcon',
        role: ['LIST_NOTIFICATIONS']
    },
    {
        title: 'تنظیمات سایت',
        icon: 'ToolIcon',
        role: ['LIST_USER_MANUAL','LIST_FAQ'],
        children: [
            // {
            //     title: 'قوانین',
            //     route: 'Policy',
            // },
            {
                title: 'اطلاعات سایت',
                route: 'SiteInfo',
                role: ['UPDATE_EXCHANGE_INFO'],
            },
            
            {
                title: 'راهنمای استفاده',
                route: 'HowToUse',
                role: ['LIST_USER_MANUAL'],
            },
            {
                title: 'افزودن راهنما',
                route: 'NewHelp',
                role: ['CREATE_USER_MANUAL']
            },
            {
                title: 'مشاهده راهنما',
                route: 'ShowHelp',
                role: ['UPDATE_USER_MANUAL'],
                disabled: true
            },
            {
                title: 'سوالات متداول',
                route: 'FAQ',
                role: ['LIST_FAQ'],
            },
        ],
    },
]

